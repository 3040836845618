import axios from 'axios';
import Util from '../util';

const SearchAPI = {
  /**
   * Retrieves all users
   * @param {string} objectType - The object type
   * @param {string} query - The search query term
   * @param {string} searchBy - The search by option : Name or CustomerKey
   * @param {string} assetType -  AssetType for content categories
   * @param {string} contentType - ContentType for folders
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `search results` property - the retrieved results - and `success`
   */
  getResults: async (objectType, query, searchBy, assetType, contentType, cancelToken) => {
    const postData = {
      objectType,
      query,
      searchByValue: searchBy,
      assetType,
      contentType,
    };

    const res = await Util.catch418And403Error(
      axios.post,
      `${Util.getApiURL()}/search-studio/search`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * get Home page message
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `search results` property - the retrieved results - and `success`
   */
  getHomePageMessage: async (cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${Util.getApiURL()}/search-studio/home-message`,
      Util.apiPostCallSettings(cancelToken),
    );
    return res.data;
  },
};

export default SearchAPI;
